<script setup lang="ts">
import { BlockConfigArgumentType, BlockConfigDetailsFragment, WorkflowBlockItemFragment } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { Button, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, reactive, toRefs, watchEffect } from 'vue'
import { BlockSettingsField } from '.'
import { useWorkflowDetails } from '../composables'
import { useBlockSettingsContext } from './useBlockSettingsContext'
import { useFieldGeneration } from './useFieldGeneration'
import { useManageArguments } from './useManageArguments'

const props = defineProps<{
  selectedBlock?: WorkflowBlockItemFragment
  blockConfig?: BlockConfigDetailsFragment
  context: 'input' | 'output' | 'settings'
}>()

const { workflow } = useWorkflowDetails()

const disabled = computed(() => !workflow.value?.draft)
const { blockConfig } = toRefs(props)
const { setContext } = useBlockSettingsContext()
const { generatedFields } = useFieldGeneration()
const { addExtraArgument, canAddExtraArgument, isNameTaken } = useManageArguments()

watchEffect(() => setContext(workflow.value, props.context, blockConfig.value))

const data = reactive({
  name: '',
})

async function clickAddArgument() {
  const argumentType = props.context === 'input' ? BlockConfigArgumentType.Reference : BlockConfigArgumentType.Constant
  await addExtraArgument(data.name, argumentType)
  data.name = ''
}
</script>

<template>
  <template v-if="!generatedFields.length">
    <Row justify="center" padding="m">
      <p class="form-description" style="text-align: center">No {{ context }} items yet</p>
    </Row>
  </template>

  <template v-else>
    <BlockSettingsField
      v-for="field of generatedFields"
      :key="String(field.namePath)"
      :disabled="disabled"
      :field="field"
    />
  </template>

  <Form v-if="canAddExtraArgument" :class="$style.addSettingsForm" @submit="clickAddArgument">
    <hr />
    <h4>Add a new {{ context }} item</h4>
    <FormItem>
      <Row>
        <TextInput
          v-model="data.name"
          style="flex: 1"
          type="text"
          autocomplete="off"
          :disabled="disabled"
          placeholder="Give in a name"
        />
        <Button :disabled="disabled || !isNameTaken" type="submit" variant="link" square>
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>

<style module>
.addSettingsForm {
  margin-top: auto;
  padding: var(--dodo-spacing-m) 0;
}
</style>
