import { getSdk } from '@/generated/sdk'
import { GraphQLClient } from 'graphql-request'
import { computed, ref } from 'vue'

const endpoint = import.meta.env.VITE_APP_API_URL as string
if (!endpoint) throw new Error('VITE_APP_API_URL is not defined')

const authToken = ref('')

// track pending calls for loading indicators
type PendingCall = { action: () => Promise<unknown>; operationName: string; startedAt: number; id: number }
const pendingCalls = ref<PendingCall[]>([])
let nextCallId = 1

const headers = computed(() => ({
  'X-Authorization-Method': 'jwt',
  Authorization: `Bearer ${authToken.value}`,
}))

const graphql = new GraphQLClient(endpoint)
graphql.setHeaders(headers.value)

const client = getSdk(graphql, async (action, operationName) => {
  // Todo: Wrap with error handling here
  const startedAt = Date.now()
  const id = nextCallId++
  const callInfo: PendingCall = { action, operationName, startedAt, id }
  pendingCalls.value.push(callInfo)
  return action().finally(() => {
    pendingCalls.value = pendingCalls.value.filter((c) => c.id !== id)
  })
})

function nullifyEmptyStr<T extends Record<string, unknown>>(obj: T) {
  const result: Record<string, unknown> = {}
  for (const key in obj) {
    const value = obj[key]
    result[key] = value === '' ? null : value
  }
  return result as { [K in keyof T]: T[K] extends string ? string | null : T[K] }
}

function setAccessToken(token: string) {
  authToken.value = token
  graphql.setHeaders(headers.value)
}

export function useApiClient() {
  async function uploadFile(file: File) {
    const originalName = file.name
    const response = await client.createFile({ input: { originalName } })
    const uploadLocation = response.createFile.uploadLocation
    if (!uploadLocation) throw new Error('Upload location not found')

    const body = new FormData()
    body.append('file', file)
    const response2 = await fetch(uploadLocation, { method: 'POST', body, headers: headers.value })
    if (!response2.ok) throw new Error('Upload failed')

    return response.createFile
  }

  async function downloadFile(file: { downloadLocation?: string | null; originalName?: string | null }) {
    const { downloadLocation } = file
    if (!downloadLocation) throw new Error('Download location not found')
    const response2 = await fetch(downloadLocation, { headers: headers.value })
    if (!response2.ok) throw new Error('Download failed')

    // Save the file using an anchor element so we can set the download filename
    const blob = await response2.blob()
    const anchor = document.createElement('a')
    if (file.originalName) anchor.download = file.originalName
    anchor.href = window.URL.createObjectURL(blob)
    anchor.click()
  }

  return { graphql, client, nullifyEmptyStr, setAccessToken, uploadFile, downloadFile, pendingCalls, endpoint }
}
