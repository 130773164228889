<script setup lang="ts">
import { MainPageLayout } from '@/navigation/components'
import { ShowMoreButton } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import { Button, Card, Row, Spinner } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { RunsTable } from '../components'
import { useRuns } from '../composables'

const { runs, refresh, showMore, deleteRun } = useRuns()
const { confirmDelete } = useConfirmDelete()
const { showMessage } = useSimpleMessage()

const filterSubworkflows = ref(true)

watch(
  filterSubworkflows,
  async () => {
    const filter = filterSubworkflows.value ? { input: { parentRun: null } } : undefined
    await refresh(filter)
  },
  { immediate: true },
)

async function clickDelete(runId: string) {
  if (await confirmDelete('this run')) {
    await deleteRun(runId)
    showMessage('Run deleted')
  }
}
</script>

<template>
  <MainPageLayout>
    <Row justify="between">
      <h1>Runs</h1>

      <Button @click="filterSubworkflows = !filterSubworkflows">
        {{ filterSubworkflows ? 'Show' : 'Hide' }} subworkflow runs
      </Button>
    </Row>

    <Spinner v-if="!runs" />
    <Card v-else>
      <RunsTable :runs="runs" @click-delete="clickDelete" />
    </Card>

    <ShowMoreButton :show-more="showMore" variant="link" />
  </MainPageLayout>
</template>
