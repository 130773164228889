import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import { BlockConfigArgumentFragment, RunInputDataItemFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { computed, reactive, ref } from 'vue'

export function useInputDataForm() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()

  const data = reactive({
    name: '',
    text: '',
    workflowId: '',
  })

  const inputDataBeingEdited = ref<RunInputDataItemFragment>()
  const isDataBeingEdited = computed(() => !!inputDataBeingEdited.value)

  const workflowInputArgs = ref<BlockConfigArgumentFragment[]>()
  const json = computed(() => tryParseJson(data.text))
  const jsonDict = computed(() => (isRecord(json.value) ? json.value : {}))

  const inputNames = computed(() => {
    const fromWorkflow = workflowInputArgs.value?.map((arg) => arg.name) ?? []
    const fromData = Object.keys(jsonDict.value)
    const allArgs = [...fromWorkflow, ...fromData]
    const uniqueArgs = [...new Set(allArgs)]
    return uniqueArgs.sort((a, b) => a.localeCompare(b))
  })

  function clear() {
    data.name = ''
    data.text = ''
    inputDataBeingEdited.value = undefined
  }

  function isRecord(obj: unknown): obj is Record<string, unknown> {
    return !!obj && typeof obj === 'object' && !Array.isArray(obj)
  }

  function tryParseJson(value: string) {
    try {
      return JSON.parse(value) as unknown
    } catch (e) {
      return value
    }
  }

  function getArgValue(argName: string) {
    const value = jsonDict.value[argName]
    if (!value) return ''
    if (typeof value === 'string') return value
    return JSON.stringify(value, null, 2)
  }

  function setArgValue(argName: string, valueText: string) {
    const value = tryParseJson(valueText)
    const newDict = Object.assign({}, jsonDict.value, { [argName]: value })
    data.text = JSON.stringify(newDict, null, 2)
  }

  function getArgError(argName: string) {
    if (!jsonDict.value[argName] && errors.text) return 'Missing value'
    return undefined
  }

  function textValidator(value: string) {
    const missingValues = inputNames.value.filter((name) => !jsonDict.value[name])
    if (missingValues.length) return `Missing values for: ${missingValues.join(', ')}`
    return undefined
  }

  const { validate, errors } = useValidation({
    workflowId: { value: computed(() => data.workflowId), required: true },
    name: { value: computed(() => data.name), required: true },
    text: { value: computed(() => data.text), required: true, validators: [textValidator] },
  })

  async function loadWorkflowArgs(workflowId: string) {
    const response = await client.workflowInputArguments({ id: workflowId })
    workflowInputArgs.value = response.workflow[0]?.blockConfigs[0]?.arguments
  }

  function loadInputDataForEdit(inputData: RunInputDataItemFragment) {
    data.name = inputData.name
    data.text = JSON.stringify(inputData.data, null, 2)
    data.workflowId = inputData.workflow.id
    inputDataBeingEdited.value = inputData
  }

  async function submit() {
    const valid = await validate()
    if (!valid) return null
    if (inputDataBeingEdited.value) {
      const update = await client.updateRunInputData({
        input: { id: inputDataBeingEdited.value.id, name: data.name, data: json.value },
      })
      clear()
      return update.updateRunInputData
    }
    const organization = { id: currentUser.value!.organization.id }
    const workflow = { id: data.workflowId }
    const input = { name: data.name, data: json.value, organization, workflow }
    const create = await client.createRunInputData({ input })
    clear()
    return create.createRunInputData
  }

  async function deleteInputData() {
    if (!inputDataBeingEdited.value) return
    const runInputDataId = inputDataBeingEdited.value.id
    const response = await client.deleteRunInputData({ runInputDataId })
    if (response) clear()
    return response
  }

  return {
    data,
    validate,
    errors,
    submit,
    clear,
    loadWorkflowArgs,
    inputNames,
    getArgValue,
    setArgValue,
    getArgError,
    loadInputDataForEdit,
    isDataBeingEdited,
    deleteInputData,
  }
}
