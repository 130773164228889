import { useApiClient } from '@/api'
import { TabularExportType } from '@/generated/sdk'
import { useBlockOutput } from '@/workflow-edit'

export function useRunResults() {
  const api = useApiClient()
  const { runs, fetchOutput, blockConfigId: blockConfigItem } = useBlockOutput()

  async function downloadResultsAllRuns(workflowId: string, type: TabularExportType) {
    const response = await api.client.downloadRunsByWorkflowId({ workflowId, type })
    await api.downloadFile(response.downloadRunsByWorkflowId)
  }

  async function downloadResultsLatestRun(blockConfigId: string, type: TabularExportType) {
    blockConfigItem.value = blockConfigId
    await fetchOutput()
    const runId = runs.value[0]?.id
    if (!runId) return
    const response = await api.client.downloadRunByRunIds({ runIds: [runId], type })
    await api.downloadFile(response.downloadRunByRunIds)
  }

  return { downloadResultsAllRuns, downloadResultsLatestRun }
}
