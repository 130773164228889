<script setup lang="ts">
import { TabularExportType } from '@/generated/sdk'
import { MainPageLayout } from '@/navigation/components'
import { useRunResults } from '@/runs'
import { provideWorkflowDetails } from '@/workflow-edit'
import { Button, Column, Dropdown, Row } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import EditorDataTable from './EditorDataTable.vue'
const props = defineProps<{
  workflowId: string
}>()

const { workflow, fetchWorkflow } = provideWorkflowDetails()
const { downloadResultsLatestRun, downloadResultsAllRuns } = useRunResults()

watch(() => props.workflowId, fetch, { immediate: true })

async function fetch() {
  workflow.value = undefined
  await fetchWorkflow(props.workflowId)
}

async function clickDownloadResultsLatestRun(type: TabularExportType) {
  const blockConfigId = workflow.value?.blockConfigs[0]!.id || ''
  await downloadResultsLatestRun(blockConfigId, type)
}
</script>

<template>
  <MainPageLayout full-screen>
    <template v-if="workflow">
      <Column gap="xl">
        <Column>
          <Row>
            <Row grow>
              <h1>Results of {{ workflow.name }}</h1>
            </Row>
            <Row>
              <Dropdown class="navbar-dropdown">
                <template #default>
                  <Button> Download </Button>
                </template>
                <template #dropdown>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="downloadResultsAllRuns(workflowId, TabularExportType.Excel)"
                  >
                    Download all (.xlsx)
                  </Button>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="clickDownloadResultsLatestRun(TabularExportType.Excel)"
                  >
                    Download latest (.xlsx)
                  </Button>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="downloadResultsAllRuns(workflowId, TabularExportType.Csv)"
                  >
                    Download all (.csv)
                  </Button>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="clickDownloadResultsLatestRun(TabularExportType.Csv)"
                  >
                    Download latest (.csv)
                  </Button>
                </template>
              </Dropdown>
            </Row>
          </Row>

          <p>
            Here you can find the latest workflow results. Every line is an instance of the workflow, called a run.<br />
            Each run has a result, as shown in the first column of the tabel below. The input used to run the workflow
            is visible in the columns after the result column.
          </p>
        </Column>

        <Column>
          <Row justify="between">
            <h3>Workflow output & input data</h3>
            <!-- <Row gap="m">
              <Button disabled>Edit columns</Button>
              <Button disabled>Filters</Button>
              <TextInput placeholder="Search" disabled />
            </Row> -->
          </Row>

          <template v-for="blockConfig in workflow.blockConfigs" :key="blockConfig.id">
            <EditorDataTable :workflow="workflow" :block-config="blockConfig" />
          </template>
        </Column>
      </Column>
    </template>
  </MainPageLayout>
</template>

<style module>
.dropdownButton {
  justify-content: start;
}
</style>
