<script setup lang="ts">
import { BlockConfigDetailsFragment, RunStatus, WorkflowDetailsFragment } from '@/generated/sdk'
import { DisplayDate, LargeModalLayout, TwinIcon } from '@/ui/components'
import ShowMoreButton from '@/ui/components/ShowMoreButton.vue'
import { useBlockOutput } from '@/workflow-edit'
import { useEditorLinks } from '@/workflow-edit/composables'
import { Button, Chip, Column, Row, Spinner } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { RouterLink } from 'vue-router'
import EditorDataCell from './EditorDataCell.vue'

const props = defineProps<{
  blockConfig: BlockConfigDetailsFragment
  workflow?: WorkflowDetailsFragment
}>()

const openColumnEditor = defineModel<boolean>('openColumnEditor')

const {
  fetchOutput,
  runs,
  allHeaders,
  inputHeaders,
  outputHeaders,
  rows,
  camelCaseToReadable,
  showMore,
  blockConfigId,
  parentWorkflow,
  workflowBlockName,
} = useBlockOutput()

const { linkWorkflowEdit } = useEditorLinks()

const isOutputFromParentWorkflow = computed(() => {
  if (!parentWorkflow.value) return false
  return parentWorkflow.value.id !== props.workflow?.id
})

watch(
  () => props.blockConfig.id,
  async () => {
    blockConfigId.value = props.blockConfig.id
    await fetchOutput()
  },
  { immediate: true },
)

const hideTable = ref(false)

// Todo: Implement CheckMarks for rows
// const selectedRunIds = ref<string[]>([])

function failedReason(index: number) {
  const run = runs.value[index]
  return run?.failedReason
}

function stacktrace(index: number) {
  const run = runs.value[index]
  return run?.stacktrace
}

function getColorForStatus(status: string) {
  switch (status) {
    case RunStatus.Done:
      return 'success'
    case RunStatus.Running:
      return 'warning'
    case RunStatus.Failed:
      return 'danger'
    default:
      return undefined
  }
}

function isOutputHeader(index: number) {
  const columnNumber = index + 1
  const standardHeadersCount = ['Run', 'Status'].length
  // Todo: Implement CheckMarks for rows
  // const standardHeadersCount = ['Run Id', 'Run', 'Status'].length
  const outputCount = (outputHeaders.value?.length || 1) + 1
  return columnNumber > standardHeadersCount && columnNumber < outputCount + standardHeadersCount
}

// Todo: Implement CheckMarks for rows
//
// function onToggleRow(row: Record<string, unknown>) {
//   const runId = row.runId
//   if (typeof runId !== 'string') return
//   const indexInSelectedRuns = selectedRunIds.value.indexOf(runId)
//   if (indexInSelectedRuns === -1) {
//     selectedRunIds.value.push(runId)
//   } else {
//     selectedRunIds.value.splice(indexInSelectedRuns, 1)
//   }
// }
</script>

<template>
  <template v-if="!runs">
    <Spinner />
  </template>
  <template v-else>
    <template v-if="isOutputFromParentWorkflow && parentWorkflow">
      <hr />
      <Row>
        <Button variant="link" color="primary" square style="margin: unset" @click="hideTable = !hideTable">
          <TwinIcon icon="ChevronDown" />
        </Button>
        <Chip>Parent workflow</Chip>
        <h4>{{ parentWorkflow.name }}</h4>
        <small v-if="workflowBlockName">{{ workflowBlockName }}</small>
        <RouterLink :to="linkWorkflowEdit(parentWorkflow.id)">
          <TwinIcon icon="LinkExternal" />
        </RouterLink>
      </Row>
    </template>

    <Row v-if="runs.length === 0" :class="$style.colorDisabled" padding="0">
      <TwinIcon icon="Info" size="s" />
      <small>No runs executed by the workflow itself</small>
    </Row>

    <div v-else v-show="!hideTable">
      <div :class="$style.EditorDataTable">
        <table>
          <thead>
            <tr>
              <th v-for="(header, i) in allHeaders" :key="header" :class="[$style.colname]">
                <Row
                  v-if="header !== 'Run Id'"
                  :class="[isOutputHeader(i) && $style.outputHeader]"
                  :title="isOutputHeader(i) ? 'Output' : undefined"
                >
                  <h4>{{ header }}</h4>
                </Row>
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(row, index) in rows" :key="row.id">
              <tr>
                <td v-for="(value, key) in row" :key="key" :class="$style.td">
                  <template v-if="key === 'runId' && typeof value === 'string'">
                    <!-- <CheckMark :checked="selectedRunIds.includes(value)" @click="onToggleRow(row)" /> -->
                  </template>

                  <template v-else-if="key === 'run' && typeof value === 'string'">
                    <Column gap="0">
                      Run name
                      <small :class="$style.colorDisabled">
                        <DisplayDate :date="parseInt(value)" />
                      </small>
                    </Column>
                  </template>

                  <template v-else-if="key === 'status' && typeof value === 'string'">
                    <EditorDataCell :value="value" name="Status" modal-title="Failed reason">
                      <template #default="{ openModal }">
                        <Row style="min-width: 96px">
                          <Chip :color="getColorForStatus(value)">{{ value }}</Chip>
                          <TwinIcon
                            v-if="value === RunStatus.Failed"
                            icon="Question"
                            :class="$style.colorDisabled"
                            style="cursor: pointer"
                            @click="openModal"
                          />
                        </Row>
                      </template>

                      <template v-if="row.status === RunStatus.Failed" #modalContent>
                        <code>{{ failedReason(index) }}</code>
                        <small>
                          <code>{{ stacktrace(index) }}</code>
                        </small>
                      </template>
                    </EditorDataCell>
                  </template>

                  <template v-else>
                    <EditorDataCell :value="value" :name="key" :modal-title="camelCaseToReadable(key)" />
                  </template>
                </td>

                <td :class="$style.filler" />
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <Row v-if="showMore" justify="center">
        <ShowMoreButton :show-more="showMore" variant="link" />
      </Row>
    </div>

    <LargeModalLayout
      v-if="openColumnEditor"
      :open="openColumnEditor"
      title="Edit columns"
      @close="() => (openColumnEditor = false)"
    >
      <template #content>
        <Column gap="m">
          <Column gap="0">
            <h4 :class="$style.listTitle">Output data</h4>
            <Row v-if="!outputHeaders?.length || outputHeaders.length === 1" :class="$style.listOptionEmpty">
              <small :class="$style.colorDisabled">Only one output available</small>
            </Row>
            <Row v-for="header in outputHeaders" v-else :key="header" :class="$style.listOption" justify="between">
              {{ camelCaseToReadable(header) }}
              <Row gap="m">
                <Button color="primary" variant="clear" square><TwinIcon icon="Drag" /></Button>
                <Button color="primary" variant="clear" square><TwinIcon icon="Eye" /></Button>
              </Row>
            </Row>
          </Column>
          <Column gap="0">
            <h4 :class="$style.listTitle">Input data</h4>
            <Row v-if="!inputHeaders?.length || inputHeaders.length === 1" :class="$style.listOptionEmpty">
              <small :class="$style.colorDisabled">Only one input available</small>
            </Row>
            <Row v-for="header in inputHeaders" v-else :key="header" :class="$style.listOption" justify="between">
              {{ camelCaseToReadable(header) }}
              <Row gap="m">
                <Button color="primary" variant="clear" square><TwinIcon icon="Drag" /></Button>
                <Button color="primary" variant="clear" square><TwinIcon icon="Eye" /></Button>
              </Row>
            </Row>
          </Column>
        </Column>
      </template>
    </LargeModalLayout>
  </template>
</template>

<style module>
.EditorDataTable {
  position: relative;
  overflow-x: auto;
}

.EditorDataTable table {
  width: 100%;
  white-space: nowrap;
}

.EditorDataTable th {
  padding-left: 8px;
  padding-right: 8px;
  padding: 0 8px 16px;
}

/* Todo: Implement CheckMarks for rows */
.EditorDataTable th:first-of-type,
.EditorDataTable .td:first-of-type {
  padding-left: 0;
}

.outputHeader {
  border-bottom: 2px solid var(--grey-3-outlines);
  background-color: var(--grey-2-lines);
  padding: 0 4px;
}

.td {
  padding: 8px;
}

.filler {
  padding: 0;
  width: 100%;
}

.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 8px;
}

.pendingRun {
  height: 90px;
  max-width: 100%;
}

.pendingRun div {
  position: absolute;
  display: flex;
  left: 0px;
  right: 0px;
  z-index: 31;
  text-align: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 90px;
  border-top: 2px solid #eee;
}

.listTitle,
.listOption {
  border-bottom: 1px solid var(--grey-2-lines);
  padding: 8px 0;
}

.listOptionEmpty {
  padding: 8px 0;
}

.colorDisabled {
  color: var(--grey-4-disabled);
}
</style>
