import { WorkflowBlockItemFragment } from '@/generated/sdk'
import { useWorkflowDetails, useWorkflowEditor } from '../composables'

export function useDataSources() {
  const { workflow } = useWorkflowDetails()
  const { getBlock } = useWorkflowEditor()

  function getPreviousBlocks(id: string): WorkflowBlockItemFragment[] {
    const workflowBlock = getBlock(id)
    if (!workflowBlock) return []

    // Recursively get all previous blocks
    const parentBlocks: WorkflowBlockItemFragment[] = []
    const unvisitedParents = [...(workflowBlock.previousBlocks ?? [])]
    while (unvisitedParents.length) {
      const { id } = unvisitedParents.pop()!
      const block = getBlock(id)
      if (!block || parentBlocks.some((b) => b.id === id)) continue // Not found or already visited
      parentBlocks.push(block)
      unvisitedParents.push(...(block.previousBlocks ?? []))
    }
    return parentBlocks
  }

  function getAvailableDataSources(workflowBlock?: WorkflowBlockItemFragment, opts?: { excludeLoopEntry?: boolean }) {
    // If no id is provided, assume that all blocks are an option
    const blockOutputs: { label: string; value: string }[] = []
    const blocks = workflowBlock ? getPreviousBlocks(workflowBlock.id) : workflow.value?.workflowBlocks || []
    for (const block of blocks) {
      const name = block.resultName || block.name || ''
      // Add entries for named blocks
      if (name) {
        blockOutputs.push({ label: name, value: name })
      }
    }
    const isInsideLoop = workflowBlock?.blockConfig?.loop
    if (isInsideLoop && !opts?.excludeLoopEntry) {
      // Add current item as an option
      blockOutputs.push({ label: 'Current item (Loop)', value: 'entry' })
    }

    const workflowConfig = workflow.value?.blockConfigs[0]
    const workflowInputs = workflowConfig?.arguments.map((a) => ({ label: a.name, value: a.name })) || []

    return [
      { groupName: 'Workflow input', references: workflowInputs },
      { groupName: 'Block output', references: blockOutputs },
    ]
  }

  return { getAvailableDataSources }
}
