<script setup lang="ts">
import { BlockArgumentInputType, BlockConfigArgumentType, FileDetailsFragment } from '@/generated/sdk'
import { FileUploadModal, LargeModalLayout } from '@/ui/components'
import TwinIcon from '@/ui/components/TwinIcon.vue'
import { Button, Column, FormItem, Row, Select, Textarea, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watchPostEffect } from 'vue'
import ChooseDatasource from './ChooseDatasource.vue'
import { useBlockSettingsField } from './useBlockSettingsField'
import type { SettingsField } from './useFieldGeneration'

const props = defineProps<{
  field: SettingsField
  disabled: boolean
  nestedLevel?: number
  onSwapItemOrder?: (direction: number) => void
  enableSwapItemOrder?: (direction: number) => boolean
}>()

const label = computed(() => {
  if (name.value === 'fileId') return 'File'
  const isArrayIndex = !isNaN(Number(name.value))
  if (isArrayIndex) return `Item ${Number(name.value) + 1}`
  return name.value
})

const { getCreateArrayItem, getFieldDelete, setArgumentType, setField, jsonString } = useBlockSettingsField()

const newValue = ref('')
const textareaModalOpen = ref(false)
const fileuploadModalOpen = ref(false)
const isReference = computed(() => props.field.argument?.argumentType === BlockConfigArgumentType.Reference)
const datasourceOpen = ref(false)

const blockTypeArg = computed(() => props.field.blockTypeArg)
const placeholder = computed(() => String(blockTypeArg.value?.defaultValue ?? ''))
const inputType = computed(() => blockTypeArg.value?.inputType ?? BlockArgumentInputType.Text)
const name = computed(() => props.field.namePath[props.field.namePath.length - 1]!)

const availableOptions = computed(() => {
  const typeArg = blockTypeArg.value
  if (typeArg?.inputType === BlockArgumentInputType.Checkbox) {
    return [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ]
  }
  if (typeArg?.options && typeArg?.inputType === BlockArgumentInputType.Select) {
    return typeArg.options.map((option) => ({
      value: option.value as string,
      label: option.label,
    }))
  }
  return null
})

const deleteField = computed(() => getFieldDelete(props.field))
const createArrayItem = computed(() => getCreateArrayItem(props.field))
const isSubfield = computed(() => (props.nestedLevel ?? 0) > 0)
const collapsable = computed(() => isSubfield.value && (props.field.items?.length || props.field.properties?.length))
const collapsed = ref(isSubfield.value)

watchPostEffect(() => {
  newValue.value = props.field.data == null ? '' : jsonString(props.field.data)
})

async function onChanged() {
  await setField(props.field, newValue.value)
}

async function setReference(value: string) {
  await setArgumentType(props.field, BlockConfigArgumentType.Reference)
  await setField(props.field, value)
}

async function setFileId(file: FileDetailsFragment) {
  await setField(props.field, file.id)
  fileuploadModalOpen.value = false
}

async function makeReference() {
  await setArgumentType(props.field, BlockConfigArgumentType.Reference)
  datasourceOpen.value = true
}

async function cancelReference() {
  await setArgumentType(props.field, BlockConfigArgumentType.Constant)
}

async function swapItemOrder(index: number, direction: number) {
  const array = props.field.data
  if (!Array.isArray(array)) return
  const newData = [...array]
  const temp = newData[index]
  const newIndex = index + direction
  if (newIndex < 0 || newIndex >= newData.length) return
  newData[index] = newData[newIndex]
  newData[newIndex] = temp
  await setField(props.field, newData)
}
</script>

<template>
  <Column gap="s">
    <Row justify="between" :class="collapsable ? $style.collapsableLabel : ''" @click="collapsed = !collapsed">
      <Row gap="xs">
        <TwinIcon v-if="collapsable" :icon="collapsed ? 'ChevronRight' : 'ChevronDown'" size="s" />
        <span class="dodo-label-text">{{ label }}</span>
      </Row>
      <Row gap="0" :class="$style.labelButtons">
        <Button
          v-if="onSwapItemOrder"
          square
          size="s"
          variant="link"
          :disabled="enableSwapItemOrder?.(1) === false"
          title="Move down"
          @click.stop="onSwapItemOrder(1)"
        >
          <TwinIcon icon="ChevronDown" size="s" />
        </Button>
        <Button
          v-if="onSwapItemOrder"
          square
          size="s"
          variant="link"
          :disabled="enableSwapItemOrder?.(-1) === false"
          title="Move up"
          @click.stop="onSwapItemOrder(-1)"
        >
          <TwinIcon icon="ChevronUp" size="s" />
        </Button>
        <Button
          v-if="deleteField"
          :disabled="disabled"
          square
          size="s"
          variant="link"
          @click.stop.prevent="deleteField!"
        >
          <TwinIcon icon="Delete" size="s" />
        </Button>
        <Button v-if="createArrayItem" :disabled="disabled" square size="s" variant="link" @click="createArrayItem">
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </Row>

    <Column v-if="!collapsable || !collapsed" gap="s">
      <template v-if="isReference">
        <ChooseDatasource
          v-model:open="datasourceOpen"
          :value="field.argument!.value"
          :disabled="disabled"
          @select="setReference"
        />
      </template>

      <Column v-else-if="field.items" gap="m" :class="$style.nestedContent">
        <template v-for="(item, i) of field.items" :key="String(item.namePath)">
          <BlockSettingsField
            :field="item"
            :disabled="disabled"
            :nested-level="(nestedLevel ?? 0) + 1"
            :on-swap-item-order="(dir) => swapItemOrder(i, dir)"
            :enable-swap-item-order="(dir) => i + dir >= 0 && i + dir < field.items!.length"
          />
        </template>
      </Column>

      <Column v-else-if="field.properties" gap="m" :class="$style.nestedContent">
        <BlockSettingsField
          v-for="subfield of field.properties"
          :key="String(subfield.namePath)"
          :field="subfield"
          :disabled="disabled"
          :nested-level="(nestedLevel ?? 0) + 1"
        />
      </Column>

      <Select
        v-else-if="availableOptions && (inputType === 'Select' || inputType === 'Checkbox')"
        v-model="newValue"
        :disabled="disabled"
        :options="availableOptions"
        :placeholder="placeholder"
        style="flex: 1"
        @change="onChanged"
      />

      <Button
        v-else-if="inputType === 'TextArea'"
        :disabled="disabled"
        style="flex: 1"
        :aria-label="`Edit ${name}`"
        @click="textareaModalOpen = true"
      >
        <TwinIcon icon="Edit" size="s" />
        Edit {{ name }}
      </Button>

      <template v-else-if="inputType === 'File'">
        <TextInput
          v-model="newValue"
          :disabled="disabled"
          :placeholder="placeholder"
          style="flex: 1"
          @click="fileuploadModalOpen = true"
        />
      </template>

      <TextInput
        v-else
        v-model="newValue"
        :disabled="disabled"
        :placeholder="placeholder"
        style="flex: 1"
        @change="onChanged"
      />

      <template v-if="field.namePath.length === 1">
        <div v-if="isReference" :class="$style.dataSourceBtn" @click="cancelReference">Disconnect data source</div>
        <div v-else :class="$style.dataSourceBtn" @click="makeReference">Data source</div>
      </template>
    </Column>
  </Column>

  <LargeModalLayout :open="textareaModalOpen" :title="`Edit ${name}`" @close="textareaModalOpen = false">
    <template #content>
      <FormItem :label="name">
        <Textarea
          v-model="newValue"
          :disabled="disabled"
          :placeholder="placeholder"
          :max-rows="20"
          @change="onChanged"
        />
      </FormItem>
    </template>
    <template #footer="{ close }">
      <Row>
        <Button @click="close">Close</Button>
      </Row>
    </template>
  </LargeModalLayout>

  <FileUploadModal :open="fileuploadModalOpen" @close="fileuploadModalOpen = false" @file-created="setFileId" />
</template>

<style module>
.dataSourceBtn {
  color: var(--grey-4-disabled);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.dataSourceBtn:hover {
  color: var(--grey-5-text);
}
.collapsableLabel {
  cursor: pointer;
  height: 32px;
}
.collapsableLabel:hover {
  color: black;
}
.labelButtons button:not(:hover) {
  color: var(--grey-4-disabled);
}
.nestedContent {
  padding-left: 16px;
}
</style>
