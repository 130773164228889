import { useApiClient, usePagination } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import { WorkflowBlockType, WorkflowItemFragment } from '@/generated/sdk'
import { useUtils } from '@/ui/composables'
import { useBlockTypes } from '@/workflow-edit'
import { onMounted, ref, watch } from 'vue'

export type { WorkflowItemFragment }

export function useWorkflows() {
  const { client } = useApiClient()
  const workflows = ref<WorkflowItemFragment[]>()
  const { pagination, showMore } = usePagination(workflows, fetch)
  const { currentUser } = useCurrentUser()
  const { fetchBlockTypeByName } = useBlockTypes()
  const { debounce } = useUtils()

  const search = ref('')

  onMounted(fetch)

  const debouncedFetch = debounce(fetch, { delayMs: 500 })

  watch(search, () => {
    workflows.value = undefined
    debouncedFetch.trigger()
  })

  async function fetch() {
    const response = await client.workflows({
      queryArgs: {
        ...pagination,
        queryString: search.value,
        // sort: [{ fieldName: 'createdAt', order: MadhatterSortOrder.Desc }],
      },
    })
    workflows.value = response.workflow
  }

  async function deleteWorkflow(workflowId: string) {
    await client.deleteWorkflow({ workflowId })
    await fetch()
  }

  async function createNew() {
    const organizationId = currentUser.value!.organization!.id
    const promptBlock = await fetchBlockTypeByName('prompt')
    const response = await client.createWorkflow({
      input: {
        name: 'New workflow',
        description: '',
        organization: { id: organizationId },
        workflowBlocks: [
          {
            name: 'Prompt 1',
            blockType: WorkflowBlockType.Normal,
            blockConfig: { block: promptBlock.id },
          },
        ],
      },
    })
    const workflow = response.createWorkflow
    return workflow
  }

  return { workflows, deleteWorkflow, showMore, createNew, search }
}
