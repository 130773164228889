<script lang="ts" setup></script>

<template>
  <div :class="$style.SidebarLayout">
    <div v-if="$slots['sidebar-left']" :class="$style.sidebarLeft">
      <slot name="sidebar-left"> sidebar left </slot>
    </div>

    <div :class="$style.content">
      <slot> content slot </slot>
    </div>

    <div v-if="$slots['sidebar-right']" :class="$style.sidebarRight">
      <slot name="sidebar-right"> sidebar right </slot>
    </div>
  </div>
</template>

<style module>
.SidebarLayout {
  display: flex;
  flex-direction: row;
  min-height: 0;
  flex-grow: 1;
}
.sidebarLeft,
.sidebarRight {
  border: 0 solid var(--grey-2-lines);
  width: 320px;
  background: var(--dodo-color-background);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: var(--dodo-spacing-m);
  padding: var(--dodo-spacing-m);
  overflow: auto;
  scrollbar-width: thin;
}
.sidebarLeft {
  border-right-width: 2px;
}
.sidebarRight {
  border-left-width: 2px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: var(--dodo-spacing-m);
  overflow: auto;
  position: relative;
}
</style>
