<script setup lang="ts">
import { useApiClient } from '@/api'
import { NavEntry, Navbar } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { RunWorkflowModal, workflowLinks } from '@/workflows'
import { Button, Dropdown, Icon, Row } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import Editor from './Editor.vue'
import { useEditorState, useEditorView, useWorkflowAutoLayout, useWorkflowDetails } from './composables'

defineProps<{
  workflowId: string
}>()

const { workflow, saveWorkflowImmediate } = useWorkflowDetails()
const router = useRouter()
const { showMessage } = useSimpleMessage()
const { centerView } = useEditorView()
const { recalculateLayout } = useWorkflowAutoLayout()
const { checkWorkflowNeedsLayout } = useEditorState()

const runWorkflowModalOpen = ref(false)

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'info' },
  { position: 'start', slot: 'more' },
  { position: 'end', slot: 'draft' },
  { position: 'end', slot: 'run' },
  { position: 'end', slot: 'exit' },
]
watch(() => workflow.value?.id, setLayoutIfNeeded, { immediate: true })

async function setLayoutIfNeeded() {
  if (workflow.value && checkWorkflowNeedsLayout()) {
    await recalculateLayout()
  }
}

onBeforeRouteLeave(() => {
  if (workflow.value?.draft) {
    // Save changes before leaving
    return saveWorkflowImmediate()
  }
})

async function clickExit() {
  await router.push(workflowLinks.workflows())
}

const { client } = useApiClient()

async function setToDraft() {
  await client.setToDraft({ workflowId: workflow.value!.id })
  workflow.value!.draft = true
  showMessage('Workflow set to draft')
}

async function setToPublished() {
  await client.setToPublished({ workflowId: workflow.value!.id })
  workflow.value!.draft = false
  showMessage('Workflow set to published')
}

async function clickAutolayout() {
  await recalculateLayout()
}

function clickCenterView() {
  centerView()
}

function onMouseWheel(e: WheelEvent) {
  const isPinchZoom = e.ctrlKey && !Number.isInteger(e.deltaY)
  if (isPinchZoom) {
    e.preventDefault()
  }
}
</script>

<template>
  <div :class="$style.EditorPage" @wheel="onMouseWheel">
    <Navbar :nav-entries="navEntries">
      <template #info>
        <Button square variant="link" disabled>
          <TwinIcon icon="Info" />
        </Button>
      </template>

      <template #more>
        <Dropdown class="navbar-dropdown">
          <template #default>
            <Button square variant="link">
              <TwinIcon icon="More" />
            </Button>
          </template>
          <template #dropdown="{ toggle }">
            <Button
              v-if="workflow?.draft"
              variant="clear"
              :class="$style.dropdownButton"
              @click="clickAutolayout(), toggle(false)"
            >
              <Icon name="Schema" :class="$style.icon" /> Autolayout
            </Button>
            <Button
              v-if="workflow?.draft"
              variant="clear"
              :class="$style.dropdownButton"
              @click="clickCenterView(), toggle(false)"
            >
              <Icon name="Recenter" :class="$style.icon" /> Center view
            </Button>
            <span v-if="workflow?.draft" :class="$style.separator"></span>
            <Button
              variant="clear"
              :class="$style.dropdownButton"
              @click="router.push(workflowLinks.workflowRunsData(workflowId))"
            >
              <TwinIcon icon="NPS" :class="$style.icon" />View output
            </Button>
            <Button variant="clear" :class="$style.dropdownButton" disabled>
              <TwinIcon icon="Clock" :class="$style.icon" />Version history
            </Button>
          </template>
        </Dropdown>
      </template>

      <template #draft>
        <Button v-if="!workflow?.draft" :class="$style.dropdownButton" @click="setToDraft">
          <TwinIcon icon="Edit" />
          Edit
        </Button>
        <Button v-if="workflow?.draft" :class="$style.dropdownButton" @click="setToPublished">
          <Icon name="Publish" />
          Publish
        </Button>
      </template>

      <template #run>
        <Button
          variant="solid"
          :disabled="workflow?.draft"
          color="primary"
          :class="$style.dropdownButton"
          @click="runWorkflowModalOpen = true"
        >
          <TwinIcon icon="Play" :class="$style.icon" />
          Run
        </Button>
      </template>

      <template #exit>
        <Row gap="xs">
          <Button @click="clickExit">Exit</Button>

          <Dropdown class="navbar-dropdown">
            <template #default>
              <Button square>
                <TwinIcon icon="ChevronDown" />
              </Button>
            </template>
            <template #dropdown>
              <Button variant="clear" :class="$style.dropdownButton" disabled>
                <TwinIcon icon="Duplicate" :class="$style.icon" />Duplicate workflow
              </Button>
              <Button variant="clear" :class="$style.dropdownButton" disabled>
                <TwinIcon icon="Delete" :class="$style.icon" />Delete workflow
              </Button>
            </template>
          </Dropdown>
        </Row>
      </template>
    </Navbar>

    <Editor />

    <RunWorkflowModal
      v-if="workflow"
      :open="runWorkflowModalOpen"
      :workflow-id="workflow.id"
      @close="runWorkflowModalOpen = false"
    />
  </div>
</template>

<style module>
.EditorPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  touch-action: none;
}

.separator {
  background: red;
  height: 2px;
  border-radius: 1px;
  background: var(--grey-2-lines);
  width: 100%;
  display: inline-block;
}

.content {
  margin-top: 10px;
}

.dropdownButton {
  justify-content: start;
}

.dropdownButton > * {
  gap: 10px;
}
</style>
