import { useApiClient } from '@/api'
import { BlockIcon, BlockItemFragment } from '@/generated/sdk'
import { computed, ref } from 'vue'

const blockTypes = ref<BlockItemFragment[]>()
const fetchPromise = ref<Promise<BlockItemFragment[]>>()

export function useBlockTypes() {
  const { client } = useApiClient()

  fetchPromise.value ??= fetch()

  async function fetch() {
    const response = await client.getBlockTypes()
    blockTypes.value = response.block
    return blockTypes.value
  }

  function getBlockTypeDetails(blockId: string | undefined): {
    icon: BlockIcon | undefined
    id: string
    readableName: string
  } {
    const blocktype = blockTypes.value?.find((b) => b.id === blockId)
    if (blocktype == null) return { icon: undefined, id: '', readableName: 'Unknown' }
    return blocktype
  }

  async function fetchBlockType(id: string | undefined | null) {
    if (id == null) return null
    const blocks = await fetchPromise.value!
    const result = blocks.find((bt) => bt.id === id)
    if (!result) throw new Error(`Block type with id ${id} not found`)
    return result
  }

  async function fetchBlockTypeByName(name: string) {
    const blocks = await fetchPromise.value!
    const result = blocks.find((bt) => bt.name === name)
    if (!result) throw new Error(`Block type with name ${name} not found`)
    return result
  }

  const blockTypeCategories = computed(() => {
    // Todo: implement server side
    const sortByName = (blockTypes: BlockItemFragment[]) => {
      return blockTypes.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
    return [{ name: 'All', blockTypes: sortByName(blockTypes.value ?? []) }]
  })

  return { blockTypes, fetchBlockType, getBlockTypeDetails, fetchBlockTypeByName, blockTypeCategories }
}
