<script setup lang="ts">
import { WorkflowBlockItemFragment } from '@/generated/sdk'
import { Button, Card, Column } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useEditorLinks } from '../composables'
import { useBlockOutput } from './useBlockOutput'

const router = useRouter()
const { runs, fetchOutput, blockConfigId } = useBlockOutput()
const { linkReviewData } = useEditorLinks()

const props = defineProps<{
  workflowId: string
  workflowBlock: WorkflowBlockItemFragment
}>()

watch(
  () => props.workflowBlock.blockConfig.id,
  async () => {
    blockConfigId.value = props.workflowBlock.blockConfig.id
    await fetchOutput()
  },
  { immediate: true },
)

async function clickReview() {
  await router.push(linkReviewData(props.workflowId, props.workflowBlock.id))
}
</script>

<template>
  <Button @click="clickReview">Review data</Button>

  <Column v-if="runs" gap="m">
    <template v-for="run in runs" :key="run.id">
      <Card>
        <small v-if="run.output == null" class="dodo-fade-secondary"><em>no output</em></small>
        <small v-else class="dodo-nowrap">{{ run.output }}</small>
      </Card>
    </template>
  </Column>
</template>
