<script setup lang="ts">
import { router } from '@/router'
import { useSimpleMessage } from '@/ui/composables'
import { workflowLinks } from '@/workflows'
import { watch } from 'vue'
import { RouterView } from 'vue-router'
import { provideWorkflowDetails } from './composables'

const props = defineProps<{
  workflowId: string
}>()

// This provides the workflow details to the children components
const { workflow, fetchWorkflow } = provideWorkflowDetails()
const { showMessage } = useSimpleMessage()

watch(() => props.workflowId, fetch, { immediate: true })

async function fetch() {
  if (props.workflowId !== workflow.value?.id) {
    await fetchWorkflow(props.workflowId)
    // Redirect if not found
    if (!workflow.value) {
      showMessage('The workflow was not found', { type: 'danger' })
      return router.replace(workflowLinks.workflows())
    }
  }
}
</script>

<template>
  <RouterView />
</template>
