<script lang="ts" setup>
import { FileDetailsFragment } from '@/generated/sdk'
import { LargeModalLayout } from '.'
import { Button, FormItem, TextInput } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useFileUploadForm } from './useFileUploadForm'
import { useSimpleMessage } from '../composables'

const props = defineProps<{
  open: boolean
  nameEditable?: boolean
}>()

const emit = defineEmits<{
  close: []
  fileCreated: [file: FileDetailsFragment, name: string]
}>()

const { data, reset, submit, errors } = useFileUploadForm()
const { showMessage } = useSimpleMessage()

watch(() => props.open, onToggle, { immediate: true })

function onToggle() {
  // Reset the form when the modal is opened
  if (props.open) {
    reset()
  }
}

async function clickSubmit() {
  const newFile = await submit()
  if (!newFile) return // Validation failed

  showMessage('Uploaded successfully')
  emit('fileCreated', newFile, data.name)
}
</script>

<template>
  <LargeModalLayout :open="open" title="Upload related file" @close="$emit('close')">
    <template #content>
      <FormItem label="File" :error="errors.file">
        <input type="file" @change="data.file = ($event.target as HTMLInputElement).files?.[0]" />
      </FormItem>

      <FormItem :optional="nameEditable" label="Name" :error="errors.name">
        <TextInput v-model="data.name" :placeholder="data.file?.name" :disabled="!nameEditable" />
      </FormItem>
    </template>

    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button color="primary" variant="solid" @click="clickSubmit()">Submit</Button>
    </template>
  </LargeModalLayout>
</template>
