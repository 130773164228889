import { useApiClient, usePagination } from '@/api'
import { RunInputDataItemFragment } from '@/generated/sdk'
import { useUtils } from '@/ui/composables'
import { useValidation } from '@madxnl/dodo-ui'
import { computed, reactive, ref, watch } from 'vue'

export function useRunWorkflowForm() {
  const { client } = useApiClient()
  const { debounce } = useUtils()

  const data = reactive({
    workflowId: '',
    name: '',
    inputData: null as RunInputDataItemFragment | null,
  })

  const search = ref('')
  const availableInputDatas = ref<RunInputDataItemFragment[]>()
  const { showMore, pagination } = usePagination(availableInputDatas, refreshOptions)

  const debouncedLoadOptions = debounce(refreshOptions)
  watch(search, debouncedLoadOptions.trigger)

  async function refreshOptions() {
    const queryArgs = { ...pagination, queryString: search.value }
    const input = { workflow: { id: data.workflowId } }
    const response = await client.runInputData({ input, queryArgs })
    availableInputDatas.value = response.runInputData
  }

  async function initForm(args: { workflowId: string }) {
    clear()
    data.workflowId = args.workflowId
    await refreshOptions()
  }

  const { validate, errors } = useValidation({
    inputData: { value: computed(() => data.inputData), required: true },
  })

  function clear() {
    data.workflowId = ''
    data.name = ''
    data.inputData = null
  }

  async function submit() {
    const valid = await validate()
    if (!valid) return
    // Copy the input data for the run
    const workflowId = data.workflowId
    const result = await client.runByWorkflowId({ workflowId, data: data.inputData!.data })
    clear()
    return result.runByWorkflowId.id
  }

  return { data, validate, errors, submit, search, showMore, refreshOptions, initForm, availableInputDatas, clear }
}
