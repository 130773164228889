<script lang="ts" setup>
import { Card } from '@madxnl/dodo-ui'
import { onBeforeUnmount, ref, watch } from 'vue'

const open = defineModel<boolean>('open')
const containerDiv = ref<HTMLElement | null>(null)
const menuDiv = ref<HTMLElement | null>(null)
const css = ref('')

const props = defineProps<{
  alignX?: 'left' | 'right'
  alignY?: 'top' | 'bottom'
  offset?: { x: number; y: number }
}>()

onBeforeUnmount(() => {
  window.removeEventListener('click', onPageClick)
})

watch(open, onToggle, { immediate: true })

function toggleMenu() {
  open.value = !open.value
  css.value = ''
}

function onPageClick(event: MouseEvent) {
  if (!open.value) return
  const target = event.target as HTMLElement
  const clickInside = menuDiv.value?.contains(target)
  if (!clickInside) {
    open.value = false
    event.preventDefault()
  }
}

function onToggle() {
  if (open.value) {
    setTimeout(() => {
      window.addEventListener('click', onPageClick)
      updateEachFrame()
    })
  } else {
    window.removeEventListener('click', onPageClick)
  }
}

function updateEachFrame() {
  if (!open.value) return
  css.value = calcCssPosition()
  requestAnimationFrame(updateEachFrame)
}

function calcCssPosition() {
  const containerRect = containerDiv.value?.getBoundingClientRect()
  const menuRect = menuDiv.value?.getBoundingClientRect()
  if (!containerRect || !menuRect) return ''
  // Only left side for now
  const margin = 16
  const dist = 1
  const x =
    props.alignX === 'left'
      ? containerRect.left - menuRect.width - dist + (props.offset?.x ?? 0)
      : containerRect.left + containerRect.width + dist + (props.offset?.x ?? 0)
  const y =
    props.alignY === 'top'
      ? Math.min(containerRect.top + (props.offset?.y ?? 0), window.innerHeight - menuRect.height - margin)
      : Math.min(
          containerRect.top + containerRect.height + (props.offset?.y ?? 0),
          window.innerHeight - menuRect.height - margin,
        )
  return `top: ${y}px; left: ${x}px;`
}
</script>

<template>
  <div ref="containerDiv" :class="$style.ContextMenu">
    <slot name="trigger" :toggle-menu="toggleMenu">
      <button @click.prevent="toggleMenu">Show menu</button>
    </slot>
    <Teleport to="body">
      <div v-if="open" id="context-menu" ref="menuDiv" :class="$style.menu" :style="css">
        <Card padding="0" gap="0" :class="$style.card">
          <slot name="menu" :toggle-menu="toggleMenu"> Modal slot </slot>
        </Card>
      </div>
    </Teleport>
  </div>
</template>

<style module>
.ContextMenu {
  width: 100%;
  height: 100%;
}
.menu {
  position: fixed;
  z-index: 1;
  width: 320px;
  max-width: calc(100vw - 32px);
  box-sizing: border-box;
  /* Position off-screen while element sizes are not yet known */
  top: -9999px;
  left: -9999px;
}
.card {
  box-shadow: var(--popup-shadow);
  color: var(--dodo-color-text);
}
</style>
