import { useApiClient } from '@/api'
import { BlockConfigOutputFragment } from '@/generated/sdk'
import { computed, reactive, ref } from 'vue'

export function useBlockOutput() {
  const { client } = useApiClient()

  const blockConfigId = ref<string>()
  const blockOutput = ref<BlockConfigOutputFragment>()

  const page = 1
  const pagination = reactive({ size: 10, page })

  const runs = computed(() => {
    const sortedRuns = blockOutput.value?.runs.slice() || []
    sortedRuns?.sort((a, b) => parseInt(b.createdAt) - parseInt(a.createdAt))
    return sortedRuns
  })

  const rows = computed(() =>
    runs.value.map((run) => {
      // Get input/output fields
      const output = getValues(run.output, outputHeaders.value, 'output')
      const input = getValues(run.input, inputHeaders.value, 'input')

      const data: Record<string, unknown> = {
        // Todo: Implement CheckMarks for rows
        // runId: run.id,
        run: run.createdAt,
        status: run.status,
        ...output,
        ...input,
      }
      return data
    }),
  )

  const allHeaders = computed(() => {
    const firstValue = rows.value.find((obj) => obj)
    if (!firstValue) return []
    const data = firstValue as Record<string, unknown>
    if (data._modelName === 'file') {
      return ['File']
    }
    return Object.keys(firstValue).map(camelCaseToReadable)
  })

  const workflowBlockName = computed(() => blockOutput.value?.workflowBlock?.[0]?.name || null)
  const parentWorkflow = computed(
    () =>
      runs.value?.[0]?.parentRun?.blockConfig?.workflow ||
      runs.value?.[0]?.parentRun?.parentRun?.blockConfig?.workflow ||
      null,
  )

  const outputHeaders = computed(() => getHeaders(runs.value.map((run) => run.output)))
  const inputHeaders = computed(() => getHeaders(runs.value.map((run) => run.input)))

  async function fetch() {
    if (!blockConfigId.value) return
    const response = await client.getBlockConfigOutput({
      blockConfigId: blockConfigId.value,
      runArgs: { ...pagination },
    })
    return response.blockConfig[0]
  }

  async function fetchOutput() {
    blockOutput.value = await fetch()
  }

  async function loadMore() {
    pagination.page++
    const response = await fetch()
    if (!response) return
    blockOutput.value?.runs.push(...response.runs)
  }

  const showMore = computed(() => {
    const hasMore = runs.value?.length > 0 && runs.value?.length % pagination.size === 0
    return hasMore ? loadMore : null
  })

  function getValues(obj: unknown, headers: string[] | null, type: 'input' | 'output') {
    // No headers, so we only have an input/output column
    if (!headers) return { [type]: obj }

    if (typeof obj === 'object' && obj && typeof obj === 'object' && '_modelName' in obj && obj._modelName === 'file') {
      return { File: obj }
    }

    const record: Record<string, unknown> = {}
    if (obj && typeof obj === 'object') Object.assign(record, obj)

    return headers.reduce(
      (acc, header) => {
        acc[`${firstCharToUpperCase(header)}`] = header in record ? record[header] : null
        return acc
      },
      {} as Record<string, unknown>,
    )
  }

  function getHeaders(objects: unknown[]) {
    const firstValue = objects.find((obj) => obj)

    // We do not convert arrays or primitives
    if (!firstValue || Array.isArray(firstValue) || typeof firstValue !== 'object') {
      return null
    }

    return [...new Set(objects.flatMap((obj) => (obj != null && typeof obj === 'object' ? Object.keys(obj) : [])))]
  }

  function camelCaseToReadable(camelCaseString: string) {
    if (!camelCaseString) return camelCaseString
    return camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())
  }

  function firstCharToUpperCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return {
    runs,
    fetchOutput,
    allHeaders,
    outputHeaders,
    inputHeaders,
    rows,
    camelCaseToReadable,
    showMore,
    blockConfigId,
    parentWorkflow,
    workflowBlockName,
  }
}
