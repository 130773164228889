<script lang="ts" setup>
import { Button, Column, Modal, Row } from '@madxnl/dodo-ui'
import { TwinIcon } from '.'
import { useConfirmDelete } from '../composables'

const { currentDialog } = useConfirmDelete()
</script>

<template>
  <Modal :open="currentDialog.open" size="S" gap="l" @close="currentDialog.resolve!(false)">
    <Column gap="l">
      <Row>
        <h2 style="flex: 1">Are you sure?</h2>
        <Button variant="link" square @click="currentDialog.resolve!(false)">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>
      <p>Are you sure you want to delete {{ currentDialog.name || 'this item' }}? This action cannot be undone.</p>

      <Column>
        <Button @click="currentDialog.resolve!(true)">
          <TwinIcon icon="Delete" />
          Delete
        </Button>
        <Button color="primary" variant="solid" @click="currentDialog.resolve!(false)"> Cancel </Button>
      </Column>
    </Column>
  </Modal>
</template>
