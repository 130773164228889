export const workflowRoutes = {
  workflows: { name: 'workflowsRoute', path: '' },
  workflowRunsData: { name: 'workflowRunsDataRoute', path: ':workflowId/runs', props: true },
}

export const workflowLinks = {
  workflows: () => ({ name: workflowRoutes.workflows.name }),
  workflowRunsData: (workflowId: string) => ({
    name: workflowRoutes.workflowRunsData.name,
    params: { workflowId },
  }),
}
