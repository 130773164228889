import { RequireAuth } from '@/auth/components'
import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { Navigation } from '../navigation'
import { WorkflowRunsDataPage } from './datapage'
import { WorkflowOverviewPage, WorkflowsNavItem } from './overview'
import { workflowRoutes } from './routes'

export const workflowsPlugin: Plugin<{ router: Router; navigation: Navigation }> = {
  install: (_, options) => {
    // Register workflow routes under a parent route with auth guard
    options.router.addRoute({
      name: 'workflows',
      path: '/workflows',
      component: RequireAuth,
      children: [
        { ...workflowRoutes.workflows, component: WorkflowOverviewPage },
        { ...workflowRoutes.workflowRunsData, component: WorkflowRunsDataPage },
      ],
      redirect: () => ({ name: workflowRoutes.workflows.name }),
    })

    // Make dataset the default page
    options.router.addRoute({
      name: 'root',
      path: '/',
      redirect: () => ({ name: workflowRoutes.workflows.name }),
    })

    // Add workflow link to navigation
    options.navigation.navEntries.addNavEntry({
      component: WorkflowsNavItem,
      position: 'end',
      order: 0,
    })
  },
}
