<script setup lang="ts">
import { useApiClient } from '@/api'
import { BlockConfigArgumentType, BlockItemFragment, type WorkflowBlockItemFragment } from '@/generated/sdk'
import { MainPageLayout, NavEntry, Navbar } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { EditorBlockCard, useBlockTypes, useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '@/workflow-edit'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import EditorDataTable from './EditorDataTable.vue'

const { blockTypes } = useBlockTypes()

const props = defineProps<{
  workflowId: string
  workflowBlockId: string
}>()

const openColumnEditor = ref(false)

const { workflow, fetchWorkflow } = useWorkflowDetails()
const { selectedBlock, sidebarTab, getBlock } = useWorkflowEditor()
const editorLinks = useEditorLinks()

const router = useRouter()
const { client } = useApiClient()

const workflowBlock = ref<WorkflowBlockItemFragment>()
const blockType = ref<BlockItemFragment>()
const info = ref(true)

watch(() => props.workflowId && props.workflowBlockId, fetch, { immediate: true })

async function fetch() {
  if (props.workflowId !== workflow.value?.id) {
    await fetchWorkflow(props.workflowId)
  }
  const res = await client.getWorkflowBlock({ id: props.workflowBlockId })

  workflowBlock.value = res.workflowBlock[0]!
  blockType.value = blockTypes.value?.find((bt) => bt.id === workflowBlock.value?.blockConfig.block)
}

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'back' },
  { position: 'end', slot: 'run' },
]

async function clickReturn() {
  await router.push(editorLinks.linkBackToWorkflowEdit(workflow.value!.id))
}

async function clickEditBlock() {
  selectedBlock.value = getBlock(props.workflowBlockId)!
  sidebarTab.value = 'settings'
  await router.push(editorLinks.linkBackToWorkflowEdit(workflow.value!.id))
}
</script>

<template>
  <MainPageLayout full-screen>
    <template #navbar>
      <Navbar :nav-entries="navEntries">
        <template #back>
          <Button variant="link" color="primary" @click="clickReturn">
            <TwinIcon icon="ChevronLeft" />
            Back to workflow editor
          </Button>
        </template>

        <template #run>
          <Row gap="xs">
            <Button variant="solid" color="primary" disabled>
              <Row> <TwinIcon icon="Play" />Re-run block </Row>
            </Button>
          </Row>
        </template>
      </Navbar>
    </template>

    <Column gap="xl">
      <Column>
        <h1>Review data</h1>
        <Column v-if="info" style="max-width: 770px">
          <p>
            If the block's output data looks wrong, review the block settings and the input data. If the input data is
            wrong, edit the input data. Then re-run the block to find out whether this improves the output. This is a
            temporary action — what happens in this screen stays in this screen.
          </p>
          <Row>
            <Button variant="link" color="primary" @click="info = false"> Got it </Button>
          </Row>
        </Column>
      </Column>

      <Row gap="xl" align="start">
        <Column gap="l" style="flex: 0 0 256px">
          <h3>Block</h3>
          <EditorBlockCard
            v-if="workflowBlock"
            :workflow-block="workflowBlock"
            :block-config="workflowBlock.blockConfig"
            :disabled="false"
            selected
            is-data-review
          />
        </Column>

        <Column gap="l" grow>
          <Row justify="between">
            <h3>Block settings</h3>

            <Button variant="link" color="primary" style="margin-right: 0" @click="clickEditBlock">
              Edit settings
            </Button>
          </Row>

          <template v-if="workflowBlock">
            <table :class="$style.table">
              <tr v-for="arg in workflowBlock.blockConfig.arguments" :key="arg.name">
                <td style="width: 256px">{{ arg.name }}</td>
                <td v-if="arg.argumentType === BlockConfigArgumentType.Reference">
                  <em>{{ arg.value }}</em>
                </td>
                <td v-else>{{ arg.value }}</td>
              </tr>
            </table>
          </template>
        </Column>
      </Row>

      <Column>
        <Row justify="between">
          <h3>Block output & input data</h3>
          <!-- <Row gap="m">
            <Button @click="() => (openColumnEditor = true)">Edit columns</Button>
            <Button disabled>Filters</Button>
            <TextInput placeholder="Search" disabled />
          </Row> -->
        </Row>

        <EditorDataTable
          v-if="workflowBlock"
          v-model:open-column-editor="openColumnEditor"
          :workflow="workflow"
          :block-config="workflowBlock.blockConfig"
        />
      </Column>
    </Column>
  </MainPageLayout>
</template>

<style module>
.table {
  border-bottom: 2px solid #eee;
}

.table td {
  padding: 8px 0;
  border-top: 2px solid #eee;
}

.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
