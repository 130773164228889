import { useUtils } from '@/ui/composables'
import { getCurrentInstance, onUnmounted } from 'vue'
import { useWorkflowEditor } from './useWorkflowEditor'

export function useEditorShortcut() {
  const { removeBlock, selectedBlock } = useWorkflowEditor()
  const { asyncContext } = useUtils()

  function enableGlobalShortcuts() {
    document.addEventListener('keydown', keydown)

    const hasInstance = getCurrentInstance()
    if (hasInstance) {
      onUnmounted(() => {
        document.removeEventListener('keydown', keydown)
      })
    }
  }

  function keydown(ev: KeyboardEvent) {
    const isInput = isInputElement(ev.target as HTMLElement)

    if (selectedBlock.value && isDeleteKey(ev.key) && !isInput) {
      asyncContext(() => removeBlock(selectedBlock.value!))
    }
  }

  function isInputElement(element: HTMLElement): boolean {
    return element && ['INPUT', 'TEXTAREA'].includes(element.tagName)
  }

  function isDeleteKey(key: string): boolean {
    return ['Delete', 'Backspace'].includes(key)
  }

  return { enableGlobalShortcuts }
}
