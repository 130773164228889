import { WorkflowBlockItemFragment, WorkflowBlockType } from '@/generated/sdk'
import { nextTick } from 'vue'
import { useEditorState } from './useEditorState'
import { useEditorView } from './useEditorView'
import { useWorkflowDetails } from './useWorkflowDetails'
import { useWorkflowEditor } from './useWorkflowEditor'

function simplifyGraph(workflowBlocks: WorkflowBlockItemFragment[]) {
  // Returns a block graph with no cycles in it for visual layout
  const connected = new Set<WorkflowBlockItemFragment>()
  const edges = new Map<WorkflowBlockItemFragment, WorkflowBlockItemFragment[]>()
  for (const block of workflowBlocks) {
    const blockEdges = edges.get(block) ?? []
    edges.set(block, blockEdges)
    for (const id of block.nextBlocks ?? []) {
      const nextBlock = workflowBlocks.find((b) => b.id === id.id)
      if (!nextBlock || connected.has(nextBlock)) continue
      connected.add(nextBlock)
      blockEdges.push(nextBlock)
    }
    if (block.blockType === WorkflowBlockType.IfElse) {
      // Sort child blocks for 'true' before 'false'
      blockEdges.sort((a, b) => -(a.condition?.localeCompare(b.condition ?? '') ?? 0))
    } else {
      // Sort child blocks by condition alphabetically
      blockEdges.sort((a, b) => a.condition?.localeCompare(b.condition ?? '') ?? 0)
    }
  }
  return edges
}

export function useWorkflowAutoLayout() {
  const { workflow, triggerSaveWorkflow } = useWorkflowDetails()
  const { editorBlocks } = useWorkflowEditor()
  const { setBlockPosition } = useEditorState()
  const { centerView, containerRect } = useEditorView()

  async function recalculateLayout() {
    if (!workflow.value) return
    const positions = computeBlockPositions()
    await centerContainerContent(positions)
    await nextTick()
    centerView()
    triggerSaveWorkflow()
  }

  function getBlockComponent(id: string) {
    return editorBlocks.value.find((b) => b.id === id)
  }

  function computeBlockPositions() {
    // Compute a graph layout while ignoring any cycles
    const workflowBlocks = workflow.value?.workflowBlocks ?? []
    const edges = simplifyGraph(workflowBlocks)
    // const nonRootIds = new Set(Array.from(edges.values()).flatMap((e) => e.map((x) => x.id)))
    const nonRootIds = workflowBlocks.flatMap((block) => block.nextBlocks?.map((x) => x.id))
    const roots = workflowBlocks.filter((b) => !nonRootIds.includes(b.id))
    const [gapX, gapY] = [32, 64]
    const positions = new Map<string, { x: number; y: number }>()
    const graphDims = new Map<WorkflowBlockItemFragment, { width: number; height: number }>()

    function getBlockSize(workflowBlock: WorkflowBlockItemFragment) {
      // Compute the dimensions of the block for layout purposes
      const size = getBlockComponent(workflowBlock.id)?.size()
      const blockHeight = size?.height ?? 0
      const blockWidth = size?.width ?? 0
      return { blockWidth, blockHeight }
    }

    function getGraphDimensions(workflowBlock: WorkflowBlockItemFragment) {
      // Compute the dimensions of the graph rooted at block
      let dims = graphDims.get(workflowBlock)
      if (!dims) {
        const childGraphSizes = edges.get(workflowBlock)?.map((c) => getGraphDimensions(c)) ?? []
        const { blockHeight, blockWidth } = getBlockSize(workflowBlock)
        const childrenTotalWidth = childGraphSizes.reduce((s, x) => s + x.width + gapX, -gapX)
        const childrenMaxHeight = Math.max(...childGraphSizes.map((x) => x.height), 0)
        let width = Math.max(blockWidth, childrenTotalWidth)
        if (workflowBlock.blockType === WorkflowBlockType.Switch) {
          width = blockWidth + childrenTotalWidth + gapX
        }
        const height = blockHeight + gapY + childrenMaxHeight
        dims = { width, height }
        graphDims.set(workflowBlock, dims)
      }
      return dims
    }

    function updatePositionsDown(workflowBlock: WorkflowBlockItemFragment, x: number, y: number) {
      // Update positions of block and its children
      const { blockHeight, blockWidth } = getBlockSize(workflowBlock)
      const isSwitch = workflowBlock.blockType === WorkflowBlockType.Switch
      let bx = x - blockWidth / 2
      if (isSwitch) {
        bx = x - getGraphDimensions(workflowBlock).width / 2
      }
      positions.set(workflowBlock.id, { x: bx, y })
      const childY = y + blockHeight + gapY
      let totalChildWidth = 0
      for (const child of edges.get(workflowBlock) ?? []) {
        totalChildWidth += getGraphDimensions(child).width + gapX
      }
      let childX = x - (totalChildWidth - gapX) / 2
      if (isSwitch) {
        childX = bx + blockWidth + gapX
      }
      for (const child of edges.get(workflowBlock) ?? []) {
        const childGraphWidth = getGraphDimensions(child).width
        updatePositionsDown(child, childX + childGraphWidth / 2, childY)
        childX += childGraphWidth + gapX
      }
    }

    let x = 0
    for (const root of roots) {
      const size = getGraphDimensions(root)
      const y = 0
      updatePositionsDown(root, x + size.width / 2, y)
      x += size.width + gapX
    }
    return positions
  }

  async function centerContainerContent(positions: Map<string, { x: number; y: number }>) {
    const el = containerRect.value
    if (!el) return
    const min = { x: 0, y: 0 }
    const max = { x: 0, y: 0 }
    for (const [b, p] of positions.entries()) {
      const el = getBlockComponent(b)?.size()
      const width = el?.width ?? 0
      const height = el?.height ?? 0
      if (p.x < min.x) min.x = p.x
      if (p.y < min.y) min.y = p.y
      if (p.x + width > max.x) max.x = p.x + width
      if (p.y + height > max.y) max.y = p.y + height
    }
    const treeWidth = max.x - min.x
    const treeHeight = max.y - min.y
    const cx = treeWidth / 2 - min.x
    const cy = treeHeight / 2 - min.y
    for (const [b, p] of positions.entries()) {
      setBlockPosition(b, [p.x + cx, p.y + cy])
    }
  }

  return {
    recalculateLayout,
  }
}
