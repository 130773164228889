<script setup lang="ts">
import { WorkflowBlockType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useUtils } from '@/ui/composables'
import { Button, Column, FormItem, Icon, Row, Tabs, TextInput, useValidation } from '@madxnl/dodo-ui'
import { computed, onUnmounted, ref } from 'vue'
import { BlockOutput, BlockSettings } from '.'
import { useWorkflowDetails, useWorkflowEditor } from '../composables'
import LoopBlockSettings from './LoopBlockSettings.vue'
import SelectBlockType from './SelectBlockType.vue'
import SelectSubworkflow from './SelectSubworkflow.vue'

const { workflow, triggerSaveWorkflow } = useWorkflowDetails()
const { truncate } = useUtils()
const {
  selectedBlock,
  sidebarTab,
  removeBlock,
  getBlockTypeDetails,
  getNextBlocks,
  addNewBlock,
  getBlockOutputConditions,
  checkNameExists,
} = useWorkflowEditor()

const newCondition = ref('')
const newTitle = ref()

const titleValidator = (title?: string) =>
  title && checkNameExists(title, selectedBlock.value?.id) ? 'The title must be unique.' : undefined

const { validate, errors } = useValidation({
  title: { value: newTitle, required: true, maxLen: 50, validators: [titleValidator] },
})

const workflowSettingsTabs = [
  { key: 'input' as const, name: 'Input', slot: 'input' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]
const blockSettingsTabs = [
  { key: 'general' as const, name: 'General', slot: 'general' },
  { key: 'settings' as const, name: 'Settings', slot: 'settings' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]

const selectedBlockTypeDetails = computed(() => getBlockTypeDetails(selectedBlock.value?.blockConfig.block))
const nextBlocks = computed(() => getNextBlocks(selectedBlock.value!))
const workflowInput = computed(() => workflow.value?.blockConfigs?.[0])
const allowDeleteBlock = computed(() => workflow.value?.draft && workflow.value.workflowBlocks.length !== 1)
const isWorkflowBlock = computed(() => selectedBlock.value?.blockConfig.block.includes('workflow') ?? false)
const isWorkflowLoopBlock = computed(() => loopConfig.value?.block.includes('workflow') ?? false)
const loopConfig = computed(() => selectedBlock.value?.blockConfig.loop?.blockConfig)

onUnmounted(() => {
  selectedBlock.value = undefined
  newCondition.value = ''
})

async function clickNewCondition() {
  const condition = newCondition.value
  await addNewBlock({ condition, previousBlocks: [{ id: selectedBlock.value!.id }] }, { select: false })
  newCondition.value = ''
}

async function updateSelectedBlockTitle(title: string | null) {
  newTitle.value = title
  const valid = await validate()
  if (!valid) return
  selectedBlock.value!.name = newTitle.value
  triggerSaveWorkflow()
}

function modifyCondition(oldCondition: string | null | undefined, newCondition: string | null) {
  for (const b of nextBlocks.value) {
    if (b.condition === oldCondition) {
      b.condition = newCondition
    }
  }
  triggerSaveWorkflow()
}

async function clickDelete() {
  await removeBlock(selectedBlock.value!)
}
</script>

<template>
  <template v-if="workflow">
    <template v-if="selectedBlock == null">
      <h2>Workflow</h2>

      <FormItem label="Name">
        <TextInput v-model="workflow.name" :disabled="!workflow?.draft" @update:model-value="triggerSaveWorkflow" />
      </FormItem>

      <Tabs :tabs="workflowSettingsTabs" style="flex-grow: 1">
        <template #input>
          <BlockSettings :block-config="workflowInput" context="input" />
        </template>

        <template #output>
          <BlockSettings :block-config="undefined" context="output" />
        </template>
      </Tabs>
    </template>

    <template v-else>
      <Row>
        <h2 style="flex-grow: 1">{{ truncate(selectedBlock.name || selectedBlockTypeDetails.readableName, 38) }}</h2>

        <Button variant="link" square aria-label="Close" @click="selectedBlock = undefined">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>

      <Tabs
        :tabs="blockSettingsTabs"
        :tab-index="blockSettingsTabs.findIndex((t) => t.key === sidebarTab)"
        style="flex-grow: 1"
        @update:tab-index="sidebarTab = blockSettingsTabs[$event]!.key"
      >
        <template #general>
          <Column gap="l">
            <FormItem label="Title" :error="errors.title">
              <TextInput
                :model-value="selectedBlock.name"
                name="title"
                :placeholder="selectedBlockTypeDetails.readableName"
                :disabled="!workflow?.draft"
                @update:model-value="updateSelectedBlockTitle"
              />
            </FormItem>

            <FormItem v-if="selectedBlock.blockType === WorkflowBlockType.Switch" label="Conditions">
              <template v-for="(condition, i) in getBlockOutputConditions(selectedBlock)" :key="i">
                <TextInput
                  :model-value="condition"
                  :disabled="!workflow?.draft"
                  @change="modifyCondition(condition, $event.target.value)"
                />
              </template>
              <Row>
                <TextInput v-model="newCondition" placeholder="Condition to add" />
                <Button @click="clickNewCondition">
                  <Icon name="Add" size="s" />
                  Add
                </Button>
              </Row>
            </FormItem>

            <SelectBlockType :workflow-block="selectedBlock" />

            <LoopBlockSettings :workflow-block="selectedBlock" />

            <Button :disabled="!allowDeleteBlock" color="danger" @click="clickDelete">Delete block</Button>
          </Column>
        </template>

        <template #settings>
          <SelectSubworkflow v-if="isWorkflowBlock" :block-config="selectedBlock.blockConfig" />
          <SelectSubworkflow v-if="loopConfig && isWorkflowLoopBlock" :block-config="loopConfig" />

          <BlockSettings
            v-if="loopConfig"
            :selected-block="selectedBlock"
            :block-config="loopConfig"
            context="settings"
          />
          <BlockSettings
            v-else
            :selected-block="selectedBlock"
            :block-config="selectedBlock.blockConfig"
            context="settings"
          />
        </template>

        <template #output>
          <BlockOutput :workflow-id="workflow!.id" :workflow-block="selectedBlock" />
        </template>
      </Tabs>
    </template>
  </template>
</template>
