<script setup lang="ts">
import { BlockIcon } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { computed } from 'vue'
import { useBlockTypes } from '../composables'

const props = defineProps<{
  block: string // blockConfig.block
}>()

const { getBlockTypeDetails } = useBlockTypes()

const blockIcons = new Map([
  [BlockIcon.Template, { name: 'Edit', color: 'blue' }],
  [BlockIcon.Ai, { name: 'GenerativeAI', color: 'purple' }],
  [BlockIcon.Utility, { name: 'Settings', color: 'orange' }],
  [BlockIcon.Logic, { name: 'Settings', color: 'green' }],
  [BlockIcon.Run, { name: 'Play', color: 'grey' }],
] as const)

function getBlockIcon(icon: BlockIcon | undefined) {
  const fallback = { name: 'Info', color: 'grey' } as const
  return (icon ? blockIcons.get(icon) : null) ?? fallback
}

const blockType = computed(() => getBlockTypeDetails(props.block))
const blockIcon = computed(() => getBlockIcon(blockType.value?.icon))
</script>

<template>
  <span :class="[$style.iconCircle]" :style="`--color:var(--${blockIcon.color}-dark)`">
    <TwinIcon v-if="blockIcon.name" :icon="blockIcon.name" />
  </span>
</template>
`
<style module>
.iconCircle {
  --color: var(--color-blue);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: color-mix(in lab, var(--color) 25%, transparent);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color);
}
</style>
