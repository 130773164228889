<script lang="ts" setup>
import { LargeModalLayout, RelatedObjectField, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { workflowLinks } from '@/workflows/routes'
import { Button, Column, FormItem, Row, Tabs, Textarea, TextInput } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useInputDataForm } from './useInputDataForm'
import { useRunWorkflowForm } from './useRunWorkflowForm'

const props = defineProps<{
  workflowId: null | string
  open: boolean
}>()

defineEmits<{
  close: []
  createNewDataset: []
}>()

const router = useRouter()
const { showMessage } = useSimpleMessage()

const runWorkflowForm = useRunWorkflowForm()
const runDataForm = useInputDataForm()

const showCreateOrEdit = ref(false)

watch(() => props.open, onOpen, { immediate: true })

async function onOpen() {
  if (!props.open || !props.workflowId) return
  await runWorkflowForm.initForm({ workflowId: props.workflowId })
  await runDataForm.loadWorkflowArgs(props.workflowId)
}

async function clickStartRun() {
  const response = await runWorkflowForm.submit()
  if (!response) return // Validation failed
  showMessage('Running workflow')
  await router.push(workflowLinks.workflowRunsData(props.workflowId!))
}

async function clickSubmit() {
  const result = await runDataForm.submit()
  if (!result) return // Validation failed
  showMessage('Input data created')
  showCreateOrEdit.value = false
  await runWorkflowForm.refreshOptions()
  runWorkflowForm.data.inputData = result
}

function createData() {
  runDataForm.clear()
  runDataForm.data.workflowId = props.workflowId!
  showCreateOrEdit.value = true
}

function editData() {
  if (!runWorkflowForm.data.inputData) return
  runDataForm.loadInputDataForEdit(runWorkflowForm.data.inputData)
  showCreateOrEdit.value = true
}

async function deleteData() {
  const deleted = await runDataForm.deleteInputData()
  if (!deleted) return
  showMessage('Input data deleted')
  runWorkflowForm.data.inputData = null
  await runWorkflowForm.refreshOptions()
  showCreateOrEdit.value = false
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run workflow" @close="$emit('close')">
    <template #content>
      <FormItem label="Run name" optional>
        <TextInput v-model="runWorkflowForm.data.name" />
      </FormItem>

      <Column>
        <FormItem label="Input data" :error="runWorkflowForm.errors.inputData">
          <RelatedObjectField
            v-model="runWorkflowForm.data.inputData"
            v-model:search="runWorkflowForm.search.value"
            placeholder="Input data"
            :items="runWorkflowForm.availableInputDatas.value"
            :get-current-text="(item) => item.name"
            :go-to-item="false"
            :show-more="runWorkflowForm.showMore.value"
            :action-edit="editData"
            :action-create="createData"
          >
            <template #item="{ item }">
              <h4>{{ item.name }}</h4>
            </template>
          </RelatedObjectField>
        </FormItem>
      </Column>

      <Row>
        <Button color="primary" variant="solid" @click="clickStartRun()">
          <TwinIcon icon="Play" />
          Run now
        </Button>
      </Row>
    </template>
  </LargeModalLayout>

  <LargeModalLayout
    :open="showCreateOrEdit"
    :title="`${runDataForm.isDataBeingEdited.value ? 'Edit' : 'New'} input data template`"
    @close="() => (showCreateOrEdit = false)"
  >
    <template #content>
      <FormItem label="Input data name" :error="runDataForm.errors.name">
        <TextInput v-model="runDataForm.data.name" placeholder="Name your input data" />
      </FormItem>
      <Tabs
        :tabs="[
          { name: 'Arguments', slot: 'arguments' },
          { name: 'Raw JSON', slot: 'text' },
        ]"
      >
        <template #arguments>
          <p v-if="runDataForm.inputNames.value.length === 0">No workflow input arguments found, please enter text</p>
          <template v-for="argName of runDataForm.inputNames.value" :key="argName">
            <FormItem :label="argName" :error="runDataForm.getArgError(argName)">
              <Textarea
                placeholder="Enter a value"
                :model-value="runDataForm.getArgValue(argName)"
                @update:model-value="runDataForm.setArgValue(argName, $event ?? '')"
              />
            </FormItem>
          </template>
        </template>
        <template #text>
          <FormItem label="JSON text" :error="runDataForm.errors.text">
            <Textarea v-model="runDataForm.data.text" placeholder="Enter input data as text" />
          </FormItem>
        </template>
      </Tabs>
    </template>

    <template #footer="{ close }">
      <Row style="width: 100%" justify="between">
        <Row>
          <Button v-if="runDataForm.isDataBeingEdited.value" @click="deleteData">
            <TwinIcon icon="Delete" />
            Remove input data
          </Button>
        </Row>
        <Row>
          <Button @click="close">Cancel</Button>
          <Button color="primary" variant="solid" @click="clickSubmit">Save</Button>
        </Row>
      </Row>
    </template>
  </LargeModalLayout>
</template>
