import { useApiClient } from '@/api'
import { FileDetailsFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

const name = ref('')
const file = ref<File>()
const data = reactive({ name, file })

export function useFileUploadForm() {
  const { uploadFile } = useApiClient()

  function reset() {
    name.value = ''
    file.value = undefined
  }

  async function relatedFileValidator(file?: File) {
    const maxBytes = 100 * 1024 * 1024 // 100 MB
    if (file && file.size > maxBytes) return 'File is too large'
    return undefined
  }

  const { validate, errors } = useValidation({
    name: { value: name },
    file: { value: file, required: true, validators: [relatedFileValidator] },
  })

  async function submit(): Promise<FileDetailsFragment | undefined> {
    // Validate
    const valid = await validate()
    if (!valid) return
    // Upload file
    return await uploadFile(file.value!)
  }

  return { data, reset, submit, errors }
}
