<script setup lang="ts">
import { ContextMenu, TwinIcon } from '@/ui/components'
import SearchableList from '@/ui/components/SearchableList.vue'
import { Button, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, watch } from 'vue'
import { useWorkflowEditor } from '../composables'
import { useDataSources } from './useDataSources'

const { getAvailableDataSources } = useDataSources()
const { selectedBlock } = useWorkflowEditor()

const props = defineProps<{
  value: string | null
  disabled: boolean
}>()

const emit = defineEmits<{
  change: []
  select: [reference: string]
}>()

const open = defineModel<boolean>('open')

watch(open, () => (!open.value ? reset() : null), { immediate: true })

const groupedOptions = computed(() => {
  const groups = getAvailableDataSources(selectedBlock.value)
  return groups
    .map((group) => ({
      groupName: group.groupName,
      items: group.references.map((ref) => ({
        name: ref.label,
        id: ref.value,
        currentValue: ref.value === props.value,
      })),
    }))
    .filter((group) => group.items.length > 0)
})

const currentLabel = computed(() => {
  const options = groupedOptions.value.flatMap((group) => group.items)
  const option = options.find((option) => option.id === props.value)
  return option?.name ?? ''
})

function reset() {
  open.value = false
}

function select(reference: string) {
  emit('select', reference)
  reset()
}
</script>

<template>
  <ContextMenu v-model:open="open" :align-x="'left'" :align-y="'top'">
    <template #trigger="{ toggleMenu }">
      <TextInput
        :model-value="currentLabel"
        :readonly="!disabled"
        :disabled="disabled"
        :class="$style.input"
        @click="toggleMenu"
      />
    </template>

    <template #menu="{ toggleMenu }">
      <Row padding="m">
        <Row grow>
          <h3>Select data source</h3>
        </Row>
        <Button variant="link" square @click="toggleMenu">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>
      <SearchableList :grouped-options="groupedOptions" @select="select" />
    </template>
  </ContextMenu>
</template>

<style module>
.input {
  width: 100%;
  cursor: pointer;
  background-color: color-mix(in lab, var(--dodo-color-info) 5%, transparent);
}
</style>
