<script setup lang="ts">
import { BlockItemFragment } from '@/generated/sdk'
import { ContextMenu, SearchableList, TwinIcon } from '@/ui/components'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { useBlockTypes } from '../composables'

const { blockTypes } = useBlockTypes()

const open = defineModel<boolean>('open')
const action = ref<'insertblock'>()

const emit = defineEmits<{
  selectBlockType: [blockItem: BlockItemFragment]
}>()

watch(open, () => (!open.value ? reset() : null), { immediate: true })

const listOptions = computed(() => {
  if (!blockTypes.value) return
  const items = blockTypes.value
    .map((blockType) => ({
      name: blockType.readableName,
      id: blockType.id,
      blockType: blockType.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
  return [
    {
      groupName: null,
      items,
    },
  ]
})

function reset() {
  action.value = undefined
  open.value = false
}

function selectAction(option: 'insertblock') {
  action.value = option
}

function selectBlockType(blockItemId: string) {
  const blockType = blockTypes.value?.find((blockType) => blockType.id === blockItemId)
  if (!blockType) return
  emit('selectBlockType', blockType)
  reset()
}
</script>

<template>
  <ContextMenu v-model:open="open" :align-x="'right'" :align-y="'bottom'" :offset="{ x: -34, y: 32 }">
    <template #trigger>
      <slot>
        <button type="button">Show menu</button>
      </slot>
    </template>

    <template #menu>
      <Column v-if="!action" padding="m" gap="0">
        <Button variant="clear" color="primary" style="width: 100%" @click.stop="() => selectAction('insertblock')">
          <TwinIcon icon="Plus" />
          Insert block
        </Button>

        <Button variant="clear" color="primary" style="width: 100%" disabled>
          <TwinIcon icon="CreateConnection" />
          Create connection
        </Button>
      </Column>

      <template v-if="action === 'insertblock'">
        <Row padding="m">
          <Row grow class="no-interaction">
            <h3>What kind of block?</h3>
          </Row>
          <Button variant="link" square @click.stop="reset">
            <TwinIcon icon="Cross" />
          </Button>
        </Row>

        <SearchableList :grouped-options="listOptions" @select="selectBlockType" />
      </template>
    </template>
  </ContextMenu>
</template>
