// Generic lodash-like utils

import { computed, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'

export function useUtils() {
  function truncate(str: string, length: number) {
    return str.length > length ? str.slice(0, length - 1) + '…' : str
  }

  function debounce<T>(func: () => T | Promise<T>, opts?: { delayMs?: number }) {
    // Debounce function that accepts async functions
    // Previous promise will resolve if called again before delayMs
    let timeout: ReturnType<typeof setTimeout> | null = null
    let resolvePrev: ((v: null | T) => null | unknown) | null = null
    const calls = ref<unknown[][]>([])

    watch(() => calls.value, run)

    const isPending = computed(() => calls.value.length > 0)

    function cancel() {
      if (timeout) clearTimeout(timeout)
      resolvePrev?.(null)
      calls.value = []
    }

    function trigger() {
      cancel()
      calls.value.push([])
    }

    function run() {
      if (!calls.value.length) return

      if (timeout) clearTimeout(timeout)
      resolvePrev?.(null)
      const delay = opts?.delayMs ?? 500

      return new Promise<T | null>((resolve, reject) => {
        resolvePrev = resolve
        timeout = setTimeout(() => {
          resolvePrev = null
          try {
            const result = func()
            calls.value = []
            if (result instanceof Promise) {
              result.then(resolve).catch(reject)
            } else {
              resolve(result)
            }
          } catch (e) {
            reject(e)
          }
        }, delay)
      })
    }

    return { trigger, cancel, isPending }
  }

  function asyncContext<T>(fn: () => T | Promise<T>) {
    // For using await from within sync functions with error handling
    const result = ref<T>()
    const error = ref<unknown>()

    watchEffect(() => {
      if (error.value) throw error.value
    })

    const promise = fn()
    if (promise instanceof Promise) {
      promise.then((res) => (result.value = res)).catch((e) => (error.value = e))
    }

    return result
  }

  return { truncate, debounce, asyncContext }
}

export function usePointer() {
  const position = ref({ x: 0, y: 0 })

  function pointermove(e: PointerEvent) {
    position.value = { x: e.clientX, y: e.clientY }
  }

  onMounted(() => {
    document.addEventListener('pointermove', pointermove)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('pointermove', pointermove)
  })

  return { position }
}
